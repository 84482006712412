export default [
  // {
  //   "0": "OP/A1/P0/U1",
  //   "1": "A1",
  //   "2": "U1",
  //   "3": "P0",
  //   "4": 39.8,
  //   "5": 2,
  //   "6": "duża witryna wejściowa",
  //   // "6": "północ,wschód,zachód",
  //   "7": "2025",
  //   "12": "small",
  //   "13": "",
  //   "11": "/assets/files/puck/pdf/OP_A1_P0_U1_Umowa_Rezerwacyjna.pdf",
  //   "14": "service",
  //   extra: "",
  //   tour: "",
  // },
  // {
  //   "0": "OP/A1/P0/01",
  //   "1": "A1",
  //   "2": "M01",
  //   "3": "P0",
  //   "4": 35.86,
  //   "5": 2,
  //   "6": "duży ogródek",
  //   // "6": "północ,wschód,zachód",
  //   "7": "2025",
  //   "12": "small",
  //   "13": "true",
  //   "11": "/assets/files/puck/pdf/OP_A1_P0_01_Umowa_Rezerwacyjna.pdf",
  //   "14": "residential",
  //   extra: "",
  //   // tour: "https://tours.3destate.pl/Process/c7bc65cc-3411-4bf8-b692-f5f82305fd8c.3d",
  // },
  //
  // {
  //   "0": "OP/A1/P0/02",
  //   "1": "A1",
  //   "2": "M02",
  //   "3": "P0",
  //   "4": 39.68,
  //   "5": 1,
  //   "6": "duży ogródek",
  //   // "6": "północ,południe,wschód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P0_02_Umowa_Rezerwacyjna.pdf",
  //   "12": "small",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/2bd42632-4fda-4d43-a4b5-8f0b6b2533e7.3d",
  // },
  // {
  //   "0": "OP/A1/P0/03",
  //   "1": "A1",
  //   "2": "M03",
  //   "3": "P0",
  //   "4": 45.72,
  //   "5": 2,
  //   "6": "duży ogródek",
  //   // "6": "południe,wschód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P0_03_Umowa_Rezerwacyjna.pdf",
  //   "12": "small",
  //   "13": "true",
  //   "14": "residential",
  //   extra: "",
  //   // tour:
  //   //   "https://tours.3destate.pl/Process/9e07c1ac-b8f1-4b09-b216-8869ea61424a.3d",
  // },
  // {
  //   "0": "OP/A1/P0/04",
  //   "1": "A1",
  //   "2": "M04",
  //   "3": "P0",
  //   "4": 38.34,
  //   "5": 2,
  //   "6": "duży ogródek",
  //   // "6": "południe,wschód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P0_04_Umowa_Rezerwacyjna.pdf",
  //   "12": "small",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/83678891-1602-41f5-a379-05aea55eef4d.3d\n",
  // },
  // {
  //   "0": "OP/A1/P0/05",
  //   "1": "A1",
  //   "2": "M05",
  //   "3": "P0",
  //   "4": 62.11,
  //   "5": 3,
  //   "6": "duży ogródek",
  //   // "6": "południe,wschód,zachód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P0_05_Umowa_Rezerwacyjna.pdf",
  //   "12": "medium",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/4ec08d8b-cfce-44d3-93b0-400faa854bd7.3d",
  // },
  // {
  //   "0": "OP/A1/P1/06",
  //   "1": "A1",
  //   "2": "M06",
  //   "3": "P1",
  //   "4": 74.88,
  //   "5": 4,
  //   "6": "dwa balkony",
  //   // "6": "północ,południe,zachód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P1_06_Umowa_Rezerwacyjna.pdf",
  //   "12": "large",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/c861a4d0-d4f4-4343-8c66-7394a9b7d97b.3d",
  // },
  // {
  //   "0": "OP/A1/P1/07",
  //   "1": "A1",
  //   "2": "M07",
  //   "3": "P0",
  //   "4": 51.94,
  //   "5": 3,
  //   "6": "balkon",
  //   // "6": "północ,zachód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P1_07_Umowa_Rezerwacyjna.pdf",
  //   "12": "small",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/37dc3f11-6120-49ab-9604-2d3e199e424f.3d",
  // },
  // {
  //   "0": "OP/A1/P1/08",
  //   "1": "A1",
  //   "2": "M08",
  //   "3": "P1",
  //   "4": 45.72,
  //   "5": 2,
  //   "6": "duży balkon",
  //   // "6": "północ,wschód,zachód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P1_08_Umowa_Rezerwacyjna.pdf",
  //   "12": "small",
  //   "13": "true",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/5fe872b3-6907-4397-aeff-9bd249ff2158.3d",
  // },
  // {
  //   "0": "OP/A1/P1/09",
  //   "1": "A1",
  //   "2": "M09",
  //   "3": "P1",
  //   "4": 38.34,
  //   "5": 2,
  //   "6": "balkon",
  //   // "6": "północ,południe,wschód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P1_09_Umowa_Rezerwacyjna.pdf",
  //   "12": "small",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/5898bbcf-8a88-49ff-8c4e-76cbd33001b2.3d",
  // },
  // {
  //   "0": "OP/A1/P1/10",
  //   "1": "A1",
  //   "2": "M10",
  //   "3": "P1",
  //   "4": 62.15,
  //   "5": 3,
  //   "6": "dwa balkony",
  //   // "6": "południe,wschód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P1_10_Umowa_Rezerwacyjna.pdf",
  //   "12": "medium",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/9b85390f-e873-4d2e-8f2d-b22d7e1c4af5.3d",
  // },
  // {
  //   "0": "OP/A1/P2/11",
  //   "1": "A1",
  //   "2": "M11",
  //   "3": "P2",
  //   "4": 74.88,
  //   "5": 4,
  //   "6": "dwa balkony",
  //   // "6": "południe,wschód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P2_11_Umowa_Rezerwacyjna.pdf",
  //   "12": "large",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/c861a4d0-d4f4-4343-8c66-7394a9b7d97b.3d",
  // },
  // {
  //   "0": "OP/A1/P2/12",
  //   "1": "A1",
  //   "2": "M12",
  //   "3": "P2",
  //   "4": 52.25,
  //   "5": 2,
  //   "6": "balkon",
  //   // "6": "południe,wschód,zachód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P2_12_Umowa_Rezerwacyjna.pdf",
  //   "12": "medium",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/045362a4-a7ea-41fb-b6da-55c5b23b19b8.3d",
  // },
  // {
  //   "0": "OP/A1/P2/13",
  //   "1": "A1",
  //   "2": "M13",
  //   "3": "P2",
  //   "4": 45.72,
  //   "5": 2,
  //   "6": "duży balkon",
  //   // "6": "północ,południe,zachód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P2_13_Umowa_Rezerwacyjna.pdf",
  //   "12": "small",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/5fe872b3-6907-4397-aeff-9bd249ff2158.3d",
  // },
  // {
  //   "0": "OP/A1/P2/14",
  //   "1": "A1",
  //   "2": "M14",
  //   "3": "P1",
  //   "4": 38.34,
  //   "5": 2,
  //   "6": "balkon",
  //   // "6": "północ,zachód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P2_14_Umowa_Rezerwacyjna.pdf",
  //   "12": "small",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/5898bbcf-8a88-49ff-8c4e-76cbd33001b2.3d",
  // },
  // {
  //   "0": "OP/A1/P2/15",
  //   "1": "A1",
  //   "2": "M15",
  //   "3": "P2",
  //   "4": 62.15,
  //   "5": 2,
  //   "6": "dwa balkony",
  //   // "6": "północ,wschód,zachód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P2_15_Umowa_Rezerwacyjna.pdf",
  //   "12": "medium",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   // tour:
  //   //   "https://tours.3destate.pl/Process/18949b21-683b-4852-bbd4-bf05528dae57.3d",
  // },
  // {
  //   "0": "OP/A1/P3/16",
  //   "1": "A1",
  //   "2": "M16",
  //   "3": "P3",
  //   "4": 63.52,
  //   "5": 4,
  //   "6": "duży taras, balkon",
  //   // "6": "północ,południe,wschód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P3_16_Umowa_Rezerwacyjna.pdf",
  //   "12": "medium",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/5aa79b9f-859e-4b3d-8064-9a3f6ee804b7.3d",
  // },
  // {
  //   "0": "OP/A1/P3/17",
  //   "1": "A1",
  //   "2": "M17",
  //   "3": "P3",
  //   "4": 52.26,
  //   "5": 2,
  //   "6": "balkon",
  //   // "6": "południe,wschód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P3_17_Umowa_Rezerwacyjna.pdf",
  //   "12": "medium",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/045362a4-a7ea-41fb-b6da-55c5b23b19b8.3d",
  // },
  // {
  //   "0": "OP/A1/P3/18",
  //   "1": "A1",
  //   "2": "M18",
  //   "3": "P3",
  //   "4": 45.72,
  //   "5": 2,
  //   "6": "duży balkon",
  //   // "6": "południe,wschód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P3_18_Umowa_Rezerwacyjna.pdf",
  //   "12": "small",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/5fe872b3-6907-4397-aeff-9bd249ff2158.3d",
  // },
  // {
  //   "0": "OP/A1/P3/19",
  //   "1": "A1",
  //   "2": "M19",
  //   "3": "P3",
  //   "4": 30.2,
  //   "5": 1,
  //   "6": "duży taras",
  //   // "6": "południe,wschód,zachód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P3_19_Umowa_Rezerwacyjna.pdf",
  //   "12": "small",
  //   "13": "true",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/c07977b7-3b0b-45a6-9c49-cecb23d213af.3d",
  // },
  // {
  //   "0": "OP/A1/P3/20",
  //   "1": "A1",
  //   "2": "M20",
  //   "3": "P3",
  //   "4": 52.98,
  //   "5": 3,
  //   "6": "duży taras, balkon",
  //   // "6": "północ,południe,zachód",
  //   "7": "2025",
  //   "11": "/assets/files/puck/pdf/OP_A1_P3_20_Umowa_Rezerwacyjna.pdf",
  //   "12": "medium",
  //   "13": "",
  //   "14": "residential",
  //   extra: "",
  //   tour:
  //     "https://tours.3destate.pl/Process/98d54bc8-54a3-49fc-b37c-7cefc202a3e0.3d",
  // },

  {
    "0": "OP/A2/P0/01",
    "1": "A2",
    "2": "M01",
    "3": "P0",
    "4": 46.08,
    "5": 2,
    "6": "duży ogródek",
    // "6": "północ,południe,wschód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P0_01_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/5949dff2-9e75-44fc-a055-febfccffa9d8.3d",
  },
  {
    "0": "OP/A2/P0/02",
    "1": "A2",
    "2": "M02",
    "3": "P0",
    "4": 45.14,
    "5": 2,
    "6": "duży ogródek",
    // "6": "południe,wschód,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P0_02_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/a23cb880-3373-45e9-bf1a-51f06f21488b.3d",
  },
  {
    "0": "OP/A2/P0/03",
    "1": "A2",
    "2": "M03",
    "3": "P0",
    "4": 43.57,
    "5": 2,
    "6": "duży ogródek",
    // "6": "południe,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P0_03_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/b01f7b8f-252a-449b-9565-710d05510543.3d",
  },
  {
    "0": "OP/A2/P0/04",
    "1": "A2",
    "2": "M04",
    "3": "P0",
    "4": 44.31,
    "5": 2,
    "6": "duży ogródek",
    // "6": "południe,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P0_04_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/96cba146-5907-49a5-a50c-278e43df3343.3d",
  },
  {
    "0": "OP/A2/P0/05",
    "1": "A2",
    "2": "M05",
    "3": "P0",
    "4": 44.31,
    "5": 2,
    "6": "duży ogródek",
    // "6": "północ,południe,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P0_05_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/29e6ee16-743a-4ab6-97ef-c07e317810f3.3d",
  },
  {
    "0": "OP/A2/P0/06",
    "1": "A2",
    "2": "M06",
    "3": "P0",
    "4": 43.97,
    "5": 2,
    "6": "duży ogródek",
    // "6": "północ,wschód,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P0_06_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/01b18438-aa01-46c8-a1b1-b7d0d74741e0.3d",
  },
  {
    "0": "OP/A2/P1/07",
    "1": "A2",
    "2": "M07",
    "3": "P1",
    "4": 55.74,
    "5": 3,
    "6": "dwa balkony",
    // "6": "północ,południe,wschód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P1_07_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    extra: "",
    price: "474 835 zł",
    tour:
      "https://tours.3destate.pl/Process/0e936ce3-b46b-4d37-8336-50b66d2ec934.3d",
  },
  {
    "0": "OP/A2/P1/08",
    "1": "A2",
    "2": "M08",
    "3": "P1",
    "4": 45.14,
    "5": 2,
    "6": "balkon",
    // "6": "południe,wschód,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P1_08_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/2511a336-b2e9-4132-b0a6-dd0bfb365075.3d",
  },
  {
    "0": "OP/A2/P1/09",
    "1": "A2",
    "2": "M09",
    "3": "P1",
    "4": 43.57,
    "5": 2,
    "6": "balkon",
    // "6": "południe,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P1_09_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    price: "414 035 zł",
    tour:
      "https://tours.3destate.pl/Process/ecc459b0-48ed-4cc5-9104-131c9c28b2ab.3d",
  },
  {
    "0": "OP/A2/P1/10",
    "1": "A2",
    "2": "M10",
    "3": "P1",
    "4": 44.31,
    "5": 2,
    "6": "balkon",
    // "6": "południe,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P1_10_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/50c85fdf-a69d-4ec0-aaf7-c8add3d70759.3d",
  },
  {
    "0": "OP/A2/P1/11",
    "1": "A2",
    "2": "M11",
    "3": "P1",
    "4": 44.31,
    "5": 2,
    "6": "balkon",
    // "6": "północ,południe,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P1_11_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/9732c192-30d9-49ea-83d4-fd28d70581bd.3d",
  },
  {
    "0": "OP/A2/P1/12",
    "1": "A2",
    "2": "M12",
    "3": "P1",
    "4": 53.88,
    "5": 3,
    "6": "dwa balkony",
    // "6": "północ,wschód,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P1_12_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "true",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/5446c64d-cbec-4f97-981c-e10ac0d82dc0.3d",
  },
  {
    "0": "OP/A2/P2/13",
    "1": "A2",
    "2": "M13",
    "3": "P2",
    "4": 55.74,
    "5": 3,
    "6": "dwa balkony",
    // "6": "północ,południe,wschód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P2_13_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/0e936ce3-b46b-4d37-8336-50b66d2ec934.3d",
  },
  {
    "0": "OP/A2/P2/14",
    "1": "A2",
    "2": "M14",
    "3": "P2",
    "4": 45.14,
    "5": 2,
    "6": "balkon",
    // "6": "południe,wschód,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P2_14_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/2511a336-b2e9-4132-b0a6-dd0bfb365075.3d",
  },
  {
    "0": "OP/A2/P2/15",
    "1": "A2",
    "2": "M15",
    "3": "P2",
    "4": 43.57,
    "5": 2,
    "6": "balkon",
    // "6": "południe,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P2_15_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/ecc459b0-48ed-4cc5-9104-131c9c28b2ab.3d",
  },
  {
    "0": "OP/A2/P2/16",
    "1": "A2",
    "2": "M16",
    "3": "P2",
    "4": 44.31,
    "5": 2,
    "6": "balkon",
    // "6": "południe,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P2_16_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/50c85fdf-a69d-4ec0-aaf7-c8add3d70759.3d",
  },
  {
    "0": "OP/A2/P2/17",
    "1": "A2",
    "2": "M17",
    "3": "P2",
    "4": 44.31,
    "5": 2,
    "6": "balkon",
    // "6": "północ,południe,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P2_17_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/9732c192-30d9-49ea-83d4-fd28d70581bd.3d",
  },
  {
    "0": "OP/A2/P2/18",
    "1": "A2",
    "2": "M18",
    "3": "P2",
    "4": 53.88,
    "5": 3,
    "6": "dwa balkony",
    // "6": "północ,wschód,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P2_18_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/5446c64d-cbec-4f97-981c-e10ac0d82dc0.3d",
  },
  {
    "0": "OP/A2/P3/19",
    "1": "A2",
    "2": "M19",
    "3": "P3",
    "4": 47.85,
    "5": 2,
    "6": "duży taras, balkon",
    // "6": "północ,wschód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P3_19_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/a849d5f2-c958-465c-b7cb-13bf205a4db6.3d",
  },
  {
    "0": "OP/A2/P3/20",
    "1": "A2",
    "2": "M20",
    "3": "P3",
    "4": 45.14,
    "5": 2,
    "6": "balkon",
    // "6": "południe,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P3_20_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/2511a336-b2e9-4132-b0a6-dd0bfb365075.3d",
  },
  {
    "0": "OP/A2/P3/21",
    "1": "A2",
    "2": "M21",
    "3": "P3",
    "4": 43.57,
    "5": 2,
    "6": "balkon",
    // "6": "południe,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P3_21_Umowa_Rezerwacyjna.pdf",
    "12": "small",
    "13": "true",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/ecc459b0-48ed-4cc5-9104-131c9c28b2ab.3d",
  },
  {
    "0": "OP/A2/P3/22",
    "1": "A2",
    "2": "M22",
    "3": "P3",
    "4": 72.32,
    "5": 4,
    "6": "bardzo duży taras, balkon",
    // "6": "północ,południe,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P3_22_Umowa_Rezerwacyjna.pdf",
    "12": "large",
    "13": "",
    "14": "residential",
    extra: "",
    tour:
      "https://tours.3destate.pl/Process/132e947a-bea3-434e-8b08-a039caec2986.3d",
  },
  {
    "0": "OP/A2/P3/23",
    "1": "A2",
    "2": "M23",
    "3": "P3",
    "4": 53.88,
    "5": 3,
    "6": "dwa balkony",
    // "6": "północ,wschód,zachód",
    "7": "II kwartał 2025",
    "11": "/assets/files/puck/pdf/OP_A2_P3_23_Umowa_Rezerwacyjna.pdf",
    "12": "medium",
    "13": "",
    "14": "residential",
    extra: "",
    // price: "505 912 zł",
    tour:
      "https://tours.3destate.pl/Process/5446c64d-cbec-4f97-981c-e10ac0d82dc0.3d",
  },
]
